.image {
  max-width: 100%;
  height: auto;
}

.pattern_img {
  width: 100%;
  height: auto;
  margin-top: 2rem;
}

.leftText {
  color: white;
  text-align: center;
}

.rightText {
  color: white;
  margin-top: 3.5rem;
}

.headline {
  font-family: 'Ubuntu', sans-serif;
  letter-spacing: 4px;
}

h3 {
  font-family: 'Ubuntu', sans-serif;
  letter-spacing: 4px;
  color: #fdb91b;
}

.date {
  color: #fff !important;
  font-weight: bold !important;
  font-size: 1.5rem !important;
  padding: 0.3rem !important;
}
