* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #026b64;
  background-image: url('./tree_bg.jpg');

  /* Set background image to fixed (don't scroll along with the page) */
  background-attachment: fixed;

  /* Center the background image */
  background-position: center;

  /* Set the background image to repeat */
  background-repeat: repeat;

  /* Scale the background image to be as large as possible */
  background-size: cover;
}
