.mainBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  color: white;
}

.adminTextBox {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.searchContainer {
  margin-top: 2rem !important;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.searchCard {
  width: 50%;
}

@media (max-width: 700px) {
  .searchCard {
    width: 100%;
  }
}
