.mainBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  color: white;
}

.textbox {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  margin-bottom: 2rem;
}

.editable {
  display: flex;
  justify-content: space-between;
}

.diaglogContent {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.QRimage {
  width: 100%;
}
