.mainBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  color: white;
}

.adminTextBox {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.angelWrapper {
  margin-left: 10rem;
  margin-right: 10rem;
}

.card {
  height: auto;
  width: 100%;

  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;

  transition: 0.5 ease;
}

.angelCardDivider {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-color: rgb(194, 191, 191);
}

.angelHalo {
  max-width: 100%;
  height: auto;
}

.childFont {
  font-family: 'Gloria Hallelujah', cursive;
  font-weight: bold;
}

.angelCardContent {
  text-align: center;
}

.angelContainerPreview {
  margin-top: 2rem;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 0.5fr));
  grid-auto-rows: auto;
  column-gap: 2rem;
  row-gap: 2rem;

  justify-content: center;
  justify-items: center;
}

.searchContainer {
  margin-top: 2rem !important;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.searchCard {
  width: 50%;
}

@media (max-width: 500px) {
  .card {
    width: 85vw;
  }

  .angelContainer {
    justify-items: center;
    justify-content: center;
  }
}

@media (max-width: 700px) {
  .searchCard {
    width: 100%;
  }
}
