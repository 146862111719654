.mainBox {
  height: auto;
  color: white;
}

.textbox {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  margin-bottom: 2rem;
}

.editable {
  display: flex;
  justify-content: space-between;
}

.diaglogContent {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.QRimage {
  width: 100%;
}

.angelWrapper {
  margin-left: 10rem;
  margin-right: 10rem;
}

.card {
  height: auto;
  width: 100%;
  cursor: pointer;

  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;

  transition: 0.5 ease;
}

.card:hover {
  background-color: #ce4d4d;
  color: white;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22) !important;
}

.selected {
  background-color: lightgray;
  color: black;

  cursor: pointer;
  transition: 0.5 ease;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22) !important;
}

.angelContainer {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-auto-rows: auto;
  column-gap: 10rem;
  row-gap: 10rem;

  justify-content: center;
}

.angelCardDivider {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-color: rgb(194, 191, 191);
}

.angelHalo {
  max-width: 100%;
  height: auto;
}

.childFont {
  font-family: 'Gloria Hallelujah', cursive;
  font-weight: bold;
}

.angelCardContent {
  text-align: center;
}

.angelContainerPreview {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 0.5fr));
  grid-auto-rows: auto;
  column-gap: 2rem;
  row-gap: 2rem;

  justify-content: center;
  justify-items: center;
}

@media (max-width: 500px) {
  .card,
  .selected {
    width: 85vw !important;
    word-wrap: break-word;
  }

  .angelContainer {
    justify-items: center;
    justify-content: center;
  }
}

.sticky_nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: 6;

  margin-left: -10rem;
  margin-right: -10rem;
}

.alertNav {
  background-color: lightcyan;
  color: black;
  padding: 10px;
  border-radius: 10px;

  margin-left: 10rem;
  margin-right: 10rem;
}

@media (max-width: 810px) {
  .alertNav {
    margin-left: 0;
    margin-right: 0;
  }
}
